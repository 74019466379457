import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import Section from '../components/Section';
import Pagination from '../components/Pagination';

import styles from './closing.module.css';

const ClosingPage = ({ data }) => {
  return (
    <Layout>
      <Section
        heading="Closing remarks"
      >
        <p>
          So that’s it for our 2019 newsletter. There is much to show those who
          visit in October and even more to discuss. I am especially interested
          in sharing with you our environmental program. As always, our
          favourite time of year is when we get to host our members at Mount
          Mary in the beautiful spring weather surrounded by lush pastures and
          growing vines with their fresh green shoots. Until then, keep safe, stay
          healthy and happy and enjoy everything in moderation. For those that are
          unable to join us at release, have a wonderful festive season with fun,
          family friends, food, fine wine and finesse.
        </p>
        <div className={styles.signatureWrapper}>
          <Img fluid={data.signatureImage.childImageSharp.fluid} className={styles.signature} />
          <p>DAVID MIDDLETON, CEO</p>
        </div>
        <Img fluid={data.image.childImageSharp.fluid} />
        <div className={styles.buttons}>
          <a href="https://www.mountmary.com.au">Members&apos; Table login to place order</a>
          <a href="https://www.mountmary.com.au/ml_2019orderform">Order form</a>
        </div>
        <div className={styles.social}>
          <p>
            To see what’s happening throughout the year at Mount Mary, make sure you follow us on Instagram:
          </p>
          <p><a href="https://www.instagram.com/mountmaryvineyard/">@mountmaryvineyard</a></p>
          <p><a href="https://www.instagram.com/marlirussellwines/">@marlirussellwines</a></p>
          <p>We also love to see our Members’ enjoying our wines, so don’t forget to tag us.</p>
        </div>
      </Section>
      <Pagination
        backLink="/members-table"
      />
    </Layout>
  );
};

ClosingPage.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.object,
    signatureImage: PropTypes.object,
  }),
};

export const query = graphql`
  query {
    image: file(relativePath: { eq: "closing.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    signatureImage: file(relativePath: { eq: "SignatureDM_select.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default ClosingPage;
